import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';

import { resolveAbsoluteUrl } from 'utils';
import type { Image } from 'modules/blog/types';

import { IS_CS_LANG_ENV, IS_DE_LANG_ENV } from 'config/env';
import type { MessageKey } from 'modules/intl';
import type { FilterByPrefix } from 'types/utils';

import favicon16 from '../../static/images/favicon/favicon-16x16.png';
import favicon32 from '../../static/images/favicon/favicon-32x32.png';
import faviconApple from '../../static/images/favicon/apple-touch-icon.png';
import maskIcon from '../../static/images/favicon/safari-pinned-tab.svg';

const fbVerification = {
    name: `facebook-domain-verification`,
    content: IS_DE_LANG_ENV ? `mla4lmw7os9m71xj1mez3pp0vlizck` : `936f6u23yfsfvjdja2njkd6miavks9`,
};

type Meta = JSX.IntrinsicElements['meta'];
type Link = JSX.IntrinsicElements['link'];
type MetaImageSize = Omit<Image, 'url'>;

type PageId = FilterByPrefix<MessageKey, 'meta.title.'>;

export interface SeoProps {
    title?: string;
    description?: string;
    pageId?: PageId;
    lang?: string;
    image?: string;
    imageSize?: MetaImageSize;
    meta?: Meta[];
    link?: Link[];
    titleTemplate?: string;
    ogType?: string;
    siteName?: string;
}

function Seo({
    title,
    description,
    pageId,
    image = '',
    imageSize = { width: 600, height: 400 },
    lang,
    meta = [],
    link = [],
    titleTemplate,
    ogType = 'website',
    siteName = 'Ackee',
}: SeoProps) {
    const intl = useIntl();
    const { formatMessage } = intl;
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        image
                        siteUrl
                    }
                }
            }
        `,
    );

    const metaTitle = title ?? (pageId ? formatMessage({ id: `meta.title.${pageId}` }) : site.siteMetadata.title);
    const metaDescription =
        description ?? (pageId ? formatMessage({ id: `meta.description.${pageId}` }) : site.siteMetadata.description);
    const baseUrl = site.siteMetadata.siteUrl;
    const metaImage = resolveAbsoluteUrl(image || site.siteMetadata.image, baseUrl);
    const htmlLang = lang || intl.locale;

    const links = (
        [
            { rel: 'image_src', type: 'image/png', href: `${metaImage}` },
            { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${favicon16}` },
            { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
            { rel: 'apple-touch-icon', sizes: '180x180', href: `${faviconApple}` },
            { rel: 'mask-icon', color: '#0000ff', href: `${maskIcon}` },
        ] as Link[]
    ).concat(link);

    const metaTags = (
        [
            {
                name: `description`,
                content: metaDescription,
            },
            {
                property: `og:title`,
                content: metaTitle,
            },
            {
                property: `og:description`,
                content: metaDescription,
            },
            {
                property: `og:image`,
                content: metaImage,
            },
            {
                property: `og:image:type`,
                content: 'image/png',
            },
            {
                property: `og:image:width`,
                content: imageSize.width.toString(),
            },
            {
                property: `og:image:height`,
                content: imageSize.height.toString(),
            },
            {
                property: `og:locale`,
                content: htmlLang,
            },
            {
                property: `og:type`,
                content: ogType,
            },
            {
                property: `og:site_name`,
                content: siteName,
            },
            {
                name: `twitter:card`,
                content: `summary`,
            },
            {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
            },
            {
                name: `twitter:title`,
                content: metaTitle,
            },
            {
                name: `twitter:description`,
                content: metaDescription,
            },
            {
                ...(!IS_CS_LANG_ENV ? fbVerification : null),
            },
        ] as Meta[]
    ).concat(meta);

    return (
        <Helmet
            htmlAttributes={{
                lang: htmlLang,
            }}
            link={links}
            title={metaTitle}
            meta={metaTags}
            titleTemplate={titleTemplate}
        >
            <script src={withPrefix('cookies-management/index.js')} type="text/javascript" />
        </Helmet>
    );
}

export default Seo;
